@import-normalize;

@import '~@fontsource/roboto/300.css';
@import '~@fontsource/roboto/400.css';
@import '~@fontsource/roboto/500.css';
@import '~@fontsource/roboto/700.css';

* {
  user-select: none;
}

html,
body {
  margin: 0;
  padding: 0;
}
